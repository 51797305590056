import store from '@/store'
import i18n from '@/libs/i18n'

export const useOrders = () => {

  const submitValidatedOrder = (order) => {
    return new Promise((resolve, reject) => {
      if ('id' in order) {
        updateOrder(order)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewOrder(order)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewOrder = (order) => {
    return new Promise((resolve, reject) => {
      store.dispatch('order/addOrder', order)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateOrder = (order) => {
    return new Promise((resolve, reject) => {
      store.dispatch('order/updateOrder', order)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeOrder = (order) => {
    return new Promise((resolve, reject) => {
      store.dispatch('order/removeOrder', order)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const validateOrder = (order) => {
    return new Promise((resolve, reject) => {
      store.dispatch('order/validateOrder', order)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const downloadOrder = (order) => {
    store.dispatch('order/printOrder', order)
      .then(response => {
      })
      .catch(error => {
        console.log(error)
      })
  }

  const downloadOrderIncomingInvoice = (order) => {
    if (order.incomingInvoice != null && order.incomingInvoice.receipt != null) {
      let link = document.createElement('a')
      link.href = order.incomingInvoice.receipt.url
      link.target = '_blank'
      link.download = order.incomingInvoice.receipt.name + '.' + order.incomingInvoice.receipt.extension
      link.click()
    }
  }

  const recognizeIncomingInvoice = (incomingInvoiceDocument) => {
    return new Promise((resolve, reject) => {
      store.dispatch('document/recognizeDocument', [incomingInvoiceDocument, 'incomingInvoice'])
        .then(response => {
          if (response.data.paymentMethod) {
            response.data.paymentMethod.name = i18n.t(response.data.paymentMethod.name)
          }
          if (response.data.paymentTerm) {
            response.data.paymentTerm.term = i18n.t(response.data.paymentTerm.term)
          }

          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const getOrderStatus = (order) => {
    if (order._filters.some(flt => flt.key == '_filter_isArchived' && flt.value == true)) {
      return 'archived'
    } else if (order._filters.some(flt => flt.key == '_filter_isInvoicedStatus' && flt.value == true)) {
      return 'invoiced'
    } else if (order._filters.some(flt => flt.key == '_filter_isAccepted' && flt.value == true)) {
      return 'orderAccepted'
    } else if (order._filters.some(flt => flt.key == '_filter_isInternalValidated' && flt.value == true)) {
      return 'validated'
    } else {
      return 'draft'
    }
  }

  const getOrderIconStatus = (order) => {
    if (getOrderStatus(order) == 'draft') return { variant: 'secondary', icon: 'pencil-ruler' }
    else if (getOrderStatus(order) == 'archived') return { variant: 'secondary', icon: 'box' }
    else if (getOrderStatus(order) == 'validated') return { variant: 'primary', icon: 'check' }
    else if (getOrderStatus(order) == 'orderAccepted') return { variant: 'warning', icon: 'hourglass' }
    else if (getOrderStatus(order) == 'invoiced') return { variant: 'success', icon: 'file-invoice-dollar' }

  }

  return {
    submitValidatedOrder,
    removeOrder,
    validateOrder,
    downloadOrder,
    downloadOrderIncomingInvoice,
    recognizeIncomingInvoice,
    getOrderStatus,
    getOrderIconStatus
  }
}