<template>
  <b-modal
      id="1"
      :title="title"
      v-model="isOpenLocal"
      size="lg"
      scrollable
      hide-footer
      @hide="tryCloseOrderModal"
  >
    <validation-observer #default="{handleSubmit, errors}">
      <b-form @submit.prevent="handleSubmit(submitValidatedOrderLocal)">
        <form-order :order="orderLocal"
                    :formErrors="errors"/>
        <button ref="submitOrderFormRef"
                class="d-none"></button>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'

import FormOrder from '@/components/form/order/Order'

export default {
  components: {
    ValidationObserver,
    FormOrder
  },
  props: {
    order: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(false)
    const orderLocal = ref(props.order)
    const submitOrderFormRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isOpenLocal, (val) => {
      emit('update:isOpen', val)
    })
    watch(orderLocal, (val) => {
      emit('update:order', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const tryCloseOrderModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitOrderFormRef.value.click()
      }
    }

    const submitValidatedOrderLocal = () => {
      emit('submitValidatedOrder')
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isOpenLocal,
      orderLocal,
      submitOrderFormRef,

      // Computed

      // Methods
      tryCloseOrderModal,
      submitValidatedOrderLocal
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    order: function (val) {
      this.orderLocal = val
    },
    isOpen: function (val) {
      this.isOpenLocal = val
    },
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>