<template>
  <b-row>
    <b-col cols="12">

      <b-card no-body>

        <!--        Header-->
        <b-card-body>
          <b-row>
            <!--      Supplier-->
            <b-col md="8">
              <b-row>
                <b-col cols="12">
                  <field-contact
                      :name="'supplier'"
                      :contactInit="selectedContact"
                      :isCompanyOnly="true"
                      :isRequired="true"
                      :customerCompany.sync="order.supplierCompany"
                  />
                </b-col>

                <!-- Launched command-->
                <b-col cols="12">
                  <field-switch
                      :model.sync="isCommandLaunched"
                      :name="'launchedCommand'"
                  />
                </b-col>
              </b-row>


            </b-col>

            <!--      Order amounts-->
            <b-col md="4">

              <div class="w-100 mx-auto"
                   style="max-width: 12rem">

                <!--            Pre tax amount-->
                <div class="d-flex align-content-center justify-content-between">
                  <p>
                    {{ $t('PreTaxAmount:') }}
                  </p>
                  <p>
                    <b>{{ currency(order.preTaxAmount) }}</b>
                  </p>
                </div>
                <!--            <VAT-->
                <div class="d-flex align-content-center justify-content-between">
                  <p>
                    {{ $t('VAT:') }}
                  </p>
                  <p>
                    <b>{{ currency(order.vat) }}</b>
                  </p>
                </div>
                <hr class="my-50">

                <!--            Total amount-->
                <div class="d-flex align-content-center justify-content-between">
                  <p>
                    {{ $t('TotalAmount:') }}
                  </p>
                  <p>
                    <b>{{ currency(order.totalAmount) }}</b>
                  </p>
                </div>

              </div>

            </b-col>
          </b-row>

        </b-card-body>


        <!-- Spacer -->
        <hr class="order-spacing">


        <b-card-body class="pt-0">
          <b-tabs>

            <!--Products-->
            <b-tab active>
              <template #title>
                <span :class="{'text-danger font-weight-bolder': isProductsTabError}">
                  {{ capitalize($tc('product', 2)) + ' (' + order.products.length + ')' }}
                </span>
              </template>

              <b-card-text>
                <tab-products :order="order"/>
              </b-card-text>
            </b-tab>

            <!--            Terms a conditions-->
            <b-tab>
              <template #title>
                <span :class="{'text-danger font-weight-bolder': isTermsTabError}">
                  {{ capitalize($t('terms&conditions')) }}
                </span>
              </template>

              <b-card-text>
                <tab-terms :order="order"/>
              </b-card-text>

            </b-tab>

            <!--            Workflow & phase-->
            <b-tab
                :title="capitalize($t('workflow'))"
            >
              <b-card-text>
                <tab-workflow :order="order"
                              :workflows="workflows"/>
              </b-card-text>

            </b-tab>

            <!--            Launched command-->
            <b-tab
                v-if="isCommandLaunched"
            >
              <template #title>
                <span :class="{'text-danger font-weight-bolder': isOrderTabError}">
                  {{ capitalize($tc('command')) }}
                </span>
              </template>

              <b-card-text>
                <tab-launched-command :order="order"/>
              </b-card-text>
            </b-tab>

            <!--            documents-->
            <b-tab
                :title="capitalize($tc('document',2)) + ' (' + order.documents.length+')'"
            >
              <b-card-text>
                <tab-documents :order="order"/>
              </b-card-text>
            </b-tab>


          </b-tabs>
        </b-card-body>

      </b-card>

    </b-col>
  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '@/utils/filter'
import { getRoute } from '@/utils/utils'

import FieldContact from '@/components/input/Contact'
import FieldInput from '@/components/input/Input'
import FieldFile from '@/components/input/File'
import FieldSelect from '@/components/input/Select'
import fieldSwitch from '@/components/input/Switch'
import FormProducts from '../products/Products'
import CardDocument from '@/components/card/Document'
import useAPI from '@/utils/useAPI'

import TabProducts from './components/_tabProducts'
import TabTerms from './components/_tabTerms&conditions'
import TabLaunchedCommand from './components/_tabLaunchedCommand'
import TabWorkflow from './components/_tabWorkflow'
import TabDocuments from './components/_tabDocuments'

export default {
  components: {
    FieldContact,
    FieldInput,
    FieldFile,
    FieldSelect,
    fieldSwitch,
    FormProducts,
    CardDocument,

    TabProducts,
    TabTerms,
    TabLaunchedCommand,
    TabWorkflow,
    TabDocuments,
  },
  props: {
    order: {
      type: Object,
      default: () => {}
    },
    formErrors: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const displayWorkflow = ref(false)
    const isCommandLaunched = ref(false)
    const isProductsTabError = ref(false)
    const isTermsTabError = ref(false)
    const isOrderTabError = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { workflows } = useAPI()

    const selectedContact = computed(() => {
      if (props.order.supplierCompany) {
        return props.order.supplierCompany
      } else {
        return null
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (getRoute().name == 'Orders' || getRoute().name == 'Order edit') displayWorkflow.value = true

    if (
        props.order.signedDocument != null ||
        props.order.signedDocumentDate != null ||
        props.order.signedDocumentNumber != null
    ) {
      isCommandLaunched.value = true
    }

    return {
      // Components
      capitalize,
      currency,

      // Data
      displayWorkflow,
      isCommandLaunched,
      isProductsTabError,
      isTermsTabError,
      isOrderTabError,

      // Computed
      workflows,
      selectedContact,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    formErrors: function (val) {
      this.isProductsTabError = false
      this.isTermsTabError = false
      this.isOrderTabError = false

      for (const [field, errorArrray] of Object.entries(val)) {
        // console.log(field, errorArrray);

        if (
            errorArrray.length > 0 &&
            (
                field.startsWith('prix unitaire') || field.startsWith('unit price') ||
                field.startsWith('quantité') || field.startsWith('quantity')
            )
        ) {
          this.isProductsTabError = true
        }

        if (
            (
                (field == 'conditions de paiement' || field == 'payment terms') ||
                (field == 'moyen de paiement' || field == 'payment method')
            ) &&
            errorArrray.length > 0
        ) {
          this.isTermsTabError = true
        }

        if (
            (field == 'date de signature' || field == 'signature date') &&
            errorArrray.length > 0) {
          this.isOrderTabError = true
        }

      }
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>