<template>
  <generic-card
      @click="$emit('click')"
      @delete="$emit('deleteOrder')"
      :allowDelete="order.number == null"
  >

    <template #iconContent>
      <b-avatar
          size="32"
          :variant="'light-'+iconStatus.variant"
      >
        <icon
            :icon="iconStatus.icon"
        />
      </b-avatar>
    </template>

    <template #bodyContent>
      <h4>{{ order.supplierCompany._display }}</h4>

      <div class="truncate">
        {{ currency(order.preTaxAmount) }}
      </div>
      <div class="truncate d-flex justify-content-between">
        <!--        <span>{{ order.billingDate | moment("L")}}</span>-->
        <!--        <i>{{ order.logs[0]|moment('L') }}</i>-->
      </div>

    </template>
  </generic-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { currency } from '@/utils/filter'
import { useOrders } from '@/views/purchases/orders/useOrders'

import GenericCard from './Card'

export default {
  components: {
    GenericCard
  },
  props: {
    order: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const iconStatus = computed(() => {
      return getOrderIconStatus(props.order)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { getOrderIconStatus } = useOrders()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      currency,

      // Data
      iconStatus,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>