<template>
  <div>

    <search-filter
        :items="orders"
        :title="'Orders'"
        :keyTitleForAlphabeticalSort="'title'"
        :additionalSortOptions="additionalSortOptions"
        :selectedFilters="['_filter_isActive']"
        :selectedView="'list'"
        :excludedKeys="['orders']"

        :tableColumns="tableColumns"
        :isActionColumnEnabled="true"
        @columnClick="$event.number == null?selectOrder($event):$router.push({ name: 'Order view', params: { orderId: $event.id }})"
        @addItem="addOrder"
    >
      <!--      result infos-->
      <template #resultInfos="{items}">
        ({{ items.length }})
        <span>{{ currency(getTotalAmounts(items)) }} TTC</span>
      </template>

      <!--      Grid view-->
      <template v-slot:gridView="{item}">
        <card-order
            :order="item"
            @click="item.number == null?selectOrder(item):$router.push({ name: 'Order view', params: { orderId: item.id }})"
            @deleteOrder="deleteOrderAlert(item)"
        />
      </template>

      <!--      List view-->

      <!--      Cell supplier-->
      <template #listView_cell_supplierCompany="{item}">
        {{ item.supplierCompany._display }}
      </template>

      <!--      Column status-->
      <template #listView_head_status="{item}">
        <icon icon="chart-line"/>
      </template>
      <template #listView_cell_status="{item}">
        <b-avatar
            :id="`order-row-${item.id}`"
            size="32"
            :variant="`light-${getOrderIconStatus(item).variant}`"
        >
          <icon
              :icon="getOrderIconStatus(item).icon"
          />
        </b-avatar>
        <b-tooltip
            :target="`order-row-${item.id}`"
            placement="left"
        >
          <p class="mb-0">
            {{ capitalize($t(getOrderStatus(item))) }}
          </p>
        </b-tooltip>
      </template>

      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          View-->
          <button-view
              @click.native.stop="$router.push({ name: 'Order view', params: { orderId: item.id }})"
              v-b-tooltip.hover.left="capitalize($t('View'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Download-->
          <button-download
              @click.native.stop="item.incomingInvoice!= null?downloadOrderIncomingInvoice(item):downloadOrder(item)"
              v-b-tooltip.hover.left="capitalize($t('Download'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />


          <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              right
          >
            <template #button-content>
              <button-dropdown
                  :withIcon="true"
                  :withText="false"
                  :withBorder="false"
                  size="sm"
              />
            </template>

            <!--          Validate-->
            <b-dropdown-item
                v-if="item.number == null && $can('manage', 'all')"
                @click.stop="validateOrderLocal(item)">
              <icon
                  icon="check"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ $t('validate') }}</span>
            </b-dropdown-item>

            <!--          Edit-->
            <b-dropdown-item
                v-if="item.number == null && $can('manage', 'all')"
                @click.stop="$router.push({ name: 'Order edit', params: { orderId: item.id }})">
              <icon
                  icon="edit"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ $t('Edit') }}</span>
            </b-dropdown-item>

            <!--          Duplicate -->
            <b-dropdown-item
                @click.stop="duplicateOrder(item)"
            >
              <icon
                  icon="copy"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('duplicate')) }}</span>
            </b-dropdown-item>

            <!--          Accept -->
            <b-dropdown-item
                v-if="item.number != null && !isOrderAccepted(item)"
                @click.stop="acceptOrderLocal(item)"
            >
              <icon
                  icon="file-signature"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ $t('orderAccepted') }}</span>
            </b-dropdown-item>

            <!--          Billing -->
            <b-dropdown-item
                v-if="item.number != null && isOrderAccepted(item)"
                @click.stop="billOrder(item)"
            >
              <icon
                  icon="file-invoice-dollar"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ $t('SaveTheBill') }}</span>
            </b-dropdown-item>

            <!--          Archivate-->
            <b-dropdown-item
                v-if="item.isArchived == false"
                @click.stop="archivateOrder(item)"
            >
              <icon
                  icon="box"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('Archivate')) }}</span>
            </b-dropdown-item>

            <!--          Activate-->
            <b-dropdown-item
                v-if="item.isArchived == true"
                @click.stop="unarchivateOrder(item, false)"
            >
              <icon
                  icon="box-open"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('Unarchive')) }}</span>
            </b-dropdown-item>

            <!--          Delete -->
            <b-dropdown-item
                v-if="item.number ==null"
                @click.stop="deleteOrderAlert(item)"
            >
              <icon
                  icon="times"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('delete')) }}</span>
            </b-dropdown-item>

          </b-dropdown>

        </div>
      </template>

    </search-filter>

    <!-- modal order-->
    <modal-order
        :order.sync="currentOrder"
        :title="orderModalTitle"
        :isOpen.sync="orderModalShow"
        @submitValidatedOrder="submitValidatedOrderLocal"
    />

    <!-- modal order validation-->
    <modal-order-validation
        :title="$t('OrderValidation')"
        :offer.sync="currentOrder"
        :isOpen.sync="isOrderAcceptanceModalOpen"
        @submitValidatedOfferValidationForm="submitValidatedOrderLocal"
    />

    <!-- modal upload incoming invoice -->
    <modal-upload-incoming-invoice
        :singleFile.sync="newIncomingInvoiceFile"
        :documentType="'incomingInvoice'"
        :title="capitalize($tc('invoice'))"
        :isOpen.sync="incomingInvoiceByUploadModalShow"
    />

    <!-- modal incoming invoice-->
    <modal-incoming-invoice
        :incomingInvoice.sync="newIncomingInvoice!=null?newIncomingInvoice:currentOrder.incomingInvoice"
        :title="incomingInvoiceModalTitle"
        :isOpen.sync="incomingInvoiceModalShow"
        @submitValidatedIncomingInvoice="submitValidatedIncomingInvoiceLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { mixinOrders } from '@/views/purchases/orders/mixinOrders'
import { useOrders } from '@/views/purchases/orders/useOrders'

import SearchFilter from '@/components/searchFilter/SearchFilter'
import CardOrder from '@/components/card/Order'
import ModalUploadIncomingInvoice from '@/components/prompt/Upload'
import ModalIncomingInvoice from '@/components/prompt/IncomingInvoice'
import i18n from '@/libs/i18n'
import ButtonView from '@/components/button/View'
import ButtonDownload from '@/components/button/Download'
import ButtonDropdown from '@/components/button/Dropdown'

export default {
  components: {
    SearchFilter,
    CardOrder,
    ModalUploadIncomingInvoice,
    ModalIncomingInvoice,
    ButtonView,
    ButtonDownload,
    ButtonDropdown
  },
  mixins: [mixinOrders],
  props: {
    orders: {
      type: Array,
      default: () => []
    },
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const additionalSortOptions = ref([])
    const tableColumns = ref([
      { key: 'number', label: '#', sortable: true },
      { key: 'status', label: i18n.t('status'), sortable: false },
      { key: 'supplierCompany', label: i18n.t('supplier'), sortable: true },
      { key: 'preTaxAmount', label: i18n.t('preTaxAmount'), sortable: true },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    const {
      getOrderStatus,
      getOrderIconStatus,
    } = useOrders()

    const enableFilters = () => {
      if (props.orders.length) {
        props.orders[0]._filters.forEach(filter => {
          if (additionalSortOptions.value.some(el => el.key == filter.key) == false) {
            additionalSortOptions.value.push(filter)
          }
        })
      }
    }

    const isOrderAccepted = (order) => {
      return (
          order.signedDocument != null ||
          order.signedDocumentDate != null ||
          order.signedDocumentNumber != null
      )
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      additionalSortOptions,
      tableColumns,

      // Computed

      // Methods
      getOrderStatus,
      getOrderIconStatus,
      enableFilters,
      isOrderAccepted,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    orders: function () {
      this.enableFilters()
    }
  },
  methods: {},
  mounted () {
    this.enableFilters()
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>